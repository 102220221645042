import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit: number = 40, trail: String = '…'): unknown {
    let result = value || '';

    if (value) {
      if (value.length > limit) {
        result = value.substring(0, limit) + trail;
      }
    }

    return result;
  }

}
