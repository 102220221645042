import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, group, keyframes, sequence, query } from '@angular/animations';
import { GameModel } from './game.model';
import { WalletService } from '../wallet.service';
import { ContractService } from '../contract.service'
import { DatabaseService } from '../database.service'
import { Tooltip } from 'bootstrap'
import { QuerySnapshot, DocumentData } from '@angular/fire/firestore';
import * as bootstrap from 'bootstrap'
import { BalanceModel } from '../wallet-button/balance.model';
import { Toaster } from 'ngx-toast-notifications';
import { approvalRequest } from '../wallet-button/wallet-approval.js';


@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
  animations: [
    trigger('showWinner', [
      // ...
      state('ltr-shrinked', style({
        opacity: '0.2',
        width: '0%',
        margin: '0 0 0 0',
      })),
      state('rtl-shrinked', style({
        opacity: '0.2',
        width: '0%',
        margin: '0 0 0 100%'
      })),
      state('ltr-expanded', style({
        opacity: '1',
        width: '100%',
        margin: '0 0 0 0'
      })),
      state('rtl-expanded', style({
        opacity: '1',
        width: '100%',
        margin: '0 0 0 0'
      })),
      transition('ltr-shrinked => ltr-expanded', [
        animate('.3s')
      ]),
      transition('rtl-shrinked => rtl-expanded', [
        animate('.3s')
      ])
    ]),
    trigger('showTrophy', [
      state('trophy-shrinked', style({
        opacity: '1',
        transform: 'scale(0)'
      })),
      state('trophy-expanded', style({
        opacity: '1',
        transform: 'scale(0.7)'
      })),
      transition('trophy-shrinked => trophy-expanded', [
        sequence([
          animate('0.5s .4s cubic-bezier(.74,.16,.87,.5)', keyframes([
            style({ transform: 'scale(0)', offset: 0 }),
            style({ transform: 'scale(0.7)', offset: 0.6 }),
            style({ transform: 'scale(0.9) rotate(8deg)', offset: 0.8 }),
            style({ transform: 'scale(0.7) rotate(-8deg)', offset: 1 })
          ])),
          animate('1.2s .0s cubic-bezier(.51,.98,.64,.97)', keyframes([
            style({ transform: 'scale(0.7) rotate(-8deg)', offset: 0 }),
            style({ transform: 'rotate(8deg)', offset: 0.1 }),
            style({ transform: 'rotate(-8deg)', offset: 0.2 }),
            style({ transform: 'rotate(8deg)', offset: 0.3 }),
            style({ transform: 'rotate(-8deg)', offset: 0.4 }),
            style({ transform: 'rotate(8deg)', offset: 0.5 }),
            style({ transform: 'rotate(-8deg)', offset: 0.6 }),
            style({ transform: 'rotate(8deg)', offset: 0.7 }),
            style({ transform: 'rotate(-8deg)', offset: 0.8 }),
            style({ transform: 'rotate(8deg)', offset: 0.9 }),
            style({ transform: 'scale(0.7) rotate(0deg)', offset: 1 })
          ]))
        ])
      ])
    ]),
    trigger('showWinnerText', [
      // ...
      state('winnerText-shrinked', style({
        width: '0%',
        'align-items': 'center',
        margin: '0 0 0 100%'
      })),
      state('winnerText-expanded', style({
        width: '70%',
        'align-items': 'center',
        margin: '0 0 0 0'
      })),
      transition('winnerText-shrinked => winnerText-expanded', [
        animate('0.2s 0.7s')
      ])
    ]),
    trigger('hideBanner', [
      transition('banner-show => banner-hidden', [
        query('.animated', [
          animate('4000ms ease-out', style({ opacity: 0 })),
        ])
      ])
    ])
  ]
})
export class GameComponent implements OnInit, OnDestroy {

  @Input() game: GameModel;
  showWinnerAnimation: boolean = false;
  showShakingAnimation: boolean = false;
  hideBannerAnimation: boolean = false;
  winnerAccount: string;
  winnerTxHash: string;
  refreshIntervalId; any;

  constructor(
    private walletService: WalletService,
    private contractService: ContractService,
    private databaseService: DatabaseService,
    private toaster: Toaster) { }


  ngOnInit(): void {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })


    this.refreshIntervalId = setInterval(() => {
      this.checkForGameUpdate();
    }, 5000)
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    if (this.refreshIntervalId) {
      clearInterval(this.refreshIntervalId);
    }
  }

  startShaking(event: AnimationEvent) {
    this.showShakingAnimation = true;
  }

  hideBanner() {
    setTimeout((handler) => {
      this.hideBannerAnimation = true;
    }, 5000)

  }

  bannerHiddenDone(event: AnimationEvent) {
    this.showShakingAnimation = false;
    this.showWinnerAnimation = false;
    this.hideBannerAnimation = false;
  }

  winnerTextShown(event) {
    if (event.toState === "winnerText-expanded") {
      setTimeout(() => {

        //Initiate Update of next game!
        this.hideBannerAnimation = true;
      }, 5000)
    }
  }

  checkForGameUpdate() {
    this.contractService.getPlayerCount(this.game.id).subscribe(players => {
      if (this.game.players == undefined) {
        if (players.value.length > 0) {
          this.updateGame();
        }
      } else {
        if (this.game.players.length != players.value.length) {
          this.updateGame();
        }
      }
    })
  }

  updateGame() {
    this.contractService.getGameDetail(this.game.id).subscribe(gameModel => {
      if (this.game.round < gameModel.round) {
        this.winnerTxHash = undefined;
        this.winnerAccount = gameModel.winner;

        this.databaseService.getWinnerTransaction(gameModel).subscribe((winnerList: QuerySnapshot<DocumentData>) => {

          if (winnerList.size === 1) {
            this.winnerTxHash = winnerList.docs[0].data().txHash;
          }
        },
          (error) => console.log(error),
          () => {
            this.showWinnerAnimation = true;
          }
        )

        setTimeout(() => {
          this.game = gameModel;

          if (gameModel.markedForRemoval === true) {
            this.contractService.markedForRemovalSubject.next(gameModel);
          }
        }, 3000)
      } else {
        this.game = gameModel
      }




    });

  }

  hasStake(): boolean {
    return this.game.players !== undefined && this.game.players.includes(this.walletService.account);
  }

  getWinnerTransactionUrl(): string {
    return `https://mainnet.lamden.io/transactions/${this.winnerTxHash}`
  }

  withdrawStake() {
    const transaction = {
      methodName: 'withdraw',
      networkType: approvalRequest.networkType,
      networkName: approvalRequest.networkName,
      stampLimit: 100,
      kwargs: {
        gameId: this.game.id
      }
    }

    const handleWithdrawTx = (txResults) => {
      if (txResults.txBlockResult.status === 0) {
        //Toast anzeigen --> successful
        this.toaster.open({
          caption: 'Transaction successful',
          text: txResults.txHash,
          type: 'success',
        });

        this.updateGame();
        this.walletService.updateBalance();
      }
    }

    this.walletService.walletController.sendTransaction(transaction, handleWithdrawTx)

  }

  joinGame() {

    const transaction = {
      methodName: 'joinGame',
      networkType: approvalRequest.networkType,
      networkName: approvalRequest.networkName,
      stampLimit: 100,
      kwargs: {
        gameId: this.game.id,
        amount: this.game.amount
      }
    }

    const handleJoinTx = (txResults) => {

      if (txResults.txBlockResult.status === 0) {

        this.toaster.open({
          caption: 'Transaction successful',
          text: txResults.txHash,
          type: 'success',
        });

        if ("'finished'" === txResults.resultInfo.returnResult) {
          //Ich war der letzte der Runde!
          let txHash: string = txResults.txHash;

          //console.log("finished found on transaction " + txResults)

          this.databaseService.addWinner(txHash).subscribe(
            response => { },
            error => { },
            () => {
              this.updateGame();
            });
        }


        //console.log("Transaction successful!")
        //console.log(txResults);
        this.updateGame();
        this.walletService.updateBalance();
      } else {

        this.toaster.open({
          text: txResults.txHash,
          caption: 'Transaction failed',
          type: 'danger',
        });

      }
    }

    /*
     if (!$autoTx) {
         createSnack({
             title: `Liking ${thingInfo.name}`,
             body: 'Check for Lamden Wallet popup to approve transaction.',
             type: "info"
         })
     }*/


    //
    let balanceModel: BalanceModel = this.walletService.balanceSubject.getValue();

    if (this.game.amount <= balanceModel.approved) {
      this.walletService.walletController.sendTransaction(transaction, handleJoinTx)
    } else {
      let modalElement: any = document.getElementById('staticBackdrop')

      let modalHandler = (event) => {
        var modalBodyInput = modalElement.querySelector('.modal-body input')
        var priceSpan = modalElement.querySelector('#price')
        var approvedSpan = modalElement.querySelector('#approved')

        priceSpan.innerText = this.game.amount
        approvedSpan.innerText = this.walletService.balanceSubject.getValue().approved;
        modalBodyInput.value = this.game.amount;
      }


      modalElement.addEventListener('show.bs.modal', modalHandler)

      let myModal: any = new bootstrap.Modal(document.getElementById('staticBackdrop'), {});
      myModal.show();
    }

  }

}
