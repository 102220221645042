<div class="container">
    <div class="row">
        <div class="col col-12">
            <h1>FAQ</h1>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-10 mx-auto">
            <div class="accordion" id="faqExample">
                <div class="card">
                    <div class="card-header p-2" id="headingOne">
                        <h5 class="mb-0">
                            <button class="btn btn-link shadow-none" type="button" data-toggle="collapse"
                                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                How does it work?
                            </button>
                        </h5>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#faqExample">
                        <div class="card-body">
                            Every game has a fixed number of seats and a fixed price per bet. To participate you
                            have to join the game and stake the correct amount of TAU. After the last player has placed his bet a
                            random winner will
                            be picked and the money transfered to the winner's account. <br /><br />You just have to
                            be the lucky one...
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header p-2" id="headingTwo">
                        <h5 class="mb-0">
                            <button class="btn btn-link collapsed shadow-none" type="button" data-toggle="collapse"
                                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Can i withdraw my stake before the game ends?
                            </button>
                        </h5>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#faqExample">
                        <div class="card-body">
                            Yes! As long as there are free seats you can withdraw your stake whenever you want. Your whole amount
                            will be returned to your wallet (minus 0.01% fees)
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header p-2" id="headingThree">
                        <h5 class="mb-0">
                            <button class="btn btn-link collapsed shadow-none" type="button" data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Can i participate more than once at the same game?
                            </button>
                        </h5>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#faqExample">
                        <div class="card-body">
                            Yes! After making your first bet, you will have 2 options:
                            <ul>
                                <li>place another bet</li>
                                <li>withdraw your whole stake</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header p-2" id="headingFour">
                        <h5 class="mb-0">
                            <button class="btn btn-link collapsed shadow-none" type="button" data-toggle="collapse"
                                data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                How much are the fees?
                            </button>
                        </h5>
                    </div>
                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#faqExample">
                        <div class="card-body">
                            There are 2 type of fees:
                            <ul>
                                <li>Jackpot transfer fees: &nbsp;&nbsp;&nbsp;0.2% of the jackpot</li>
                                <li>Stake withdrawal fees: &nbsp;0.01% of your stake</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!--/row-->
</div>