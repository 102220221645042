import { Injectable } from '@angular/core';
import WalletController from './wallet-button/WalletController'
import { approvalRequest, baseURL } from './wallet-button/wallet-approval.js';
import { HttpClient } from '@angular/common/http'
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { BalanceModel } from './wallet-button/balance.model'


@Injectable({
  providedIn: 'root'
})
export class WalletService {

  walletController: WalletController;
  account: string;
  balanceSubject = new BehaviorSubject<BalanceModel>({
    balance: 0,
    approved: 0
  });

  constructor(private http: HttpClient) {
    this.walletController = new WalletController(approvalRequest);
  }

  setAccount(account: string) {
    this.account = account;
  }

  updateBalance(): void {
    //return 

    if (this.account === undefined) {
      return;
    }

    let approvedAmount = this.http.get<any>(`${baseURL}/contracts/currency/balances?key=${this.account},${approvalRequest.contractName}`).pipe(catchError(error => {
      return of(undefined);
    }));
    let balance = this.http.get<any>(`${baseURL}/contracts/currency/balances?key=${this.account}`).pipe(catchError(error => {
      return of(undefined);
    }));;


    forkJoin([approvedAmount, balance]).subscribe(results => {
      let result = new BalanceModel();
      result.approved = Number(results[0] == undefined ? 0 : results[0].value)
      result.balance = Number(results[1] == undefined ? 0 : results[1].value.__fixed__)

      this.balanceSubject.next(result);
    });

  }


}
