import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable, forkJoin, Subject, of } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { GameModel } from './game/game.model'
import { WinnerModel } from './game/winner.model'
import { approvalRequest, baseURL } from './wallet-button/wallet-approval.js';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(private http: HttpClient) { }

  markedForRemovalSubject = new Subject<GameModel>();

  getAllGames(): Observable<GameModel> {

    let subject = new Subject<GameModel>();

    this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/gameNames?key=names`)
      .subscribe(
        games => {
          const values = games.value as string[];
          let gameDetailObservables: Observable<GameModel>[] = [];

          values.forEach(gameName => {
            gameDetailObservables.push(this.getGameDetail(gameName));
          })

          if (gameDetailObservables.length > 0) {
            forkJoin(gameDetailObservables).subscribe(results => {
              const sortedResults = results.sort((game1, game2) => {
                return game1.jackpot - game2.jackpot;
              })

              sortedResults.forEach(game => {
                subject.next(game);
              })
            });
          }
        });

    return subject;
  }


  getWinnerInfo(gameId: string): Observable<WinnerModel> {
    const winnerObservable = new Observable<WinnerModel>(observer => {
      let winner = this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},winner`)
      let round = this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},round`)
      let amount = this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},amount`)
      let playercount = this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},playercount`)



      forkJoin([winner, round, amount, playercount]).subscribe(results => {

        let result = new WinnerModel();
        result.gameId = gameId;
        result.winner = String(results[0] == undefined ? "" : results[0].value);
        result.round = Number(results[1].value) - 1;
        result.amount = Number(results[2].value) * Number(results[3].value);
        result.date = firebase.firestore.FieldValue.serverTimestamp();

        observer.next(result);
        observer.complete();
      });

    });

    return winnerObservable;

  }

  getTransactionDetails(txHash: string): Observable<any> {
    return this.http.get<any>(`${baseURL}/tx?hash=${txHash}`)
  }

  getPlayerCount(gameId: string): Observable<any> {
    return this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},players`);
  }

  getGameDetail(gameId: string): Observable<GameModel> {

    const gameObservable = new Observable<GameModel>(observer => {

      let playercount = this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},playercount`);
      let amount = this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},amount`);
      let markedForRemoval = this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},markedForRemoval`);
      let players = this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},players`).pipe(catchError(error => {
        return of(undefined);
      }));
      let winner = this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},winner`).pipe(catchError(error => {
        return of(undefined);
      }));
      let round = this.http.get<any>(`${baseURL}/contracts/${approvalRequest.contractName}/games?key=${gameId},round`);


      forkJoin([playercount, amount, markedForRemoval, players, winner, round]).subscribe(results => {
        let result = new GameModel();
        result.id = gameId;
        result.playerCount = Number(results[0].value);
        result.amount = Number(results[1].value);
        result.jackpot = result.playerCount * result.amount;
        result.markedForRemoval = Boolean(results[2].value);
        result.players = (results[3] == undefined ? [] : results[3].value as Array<string>)
        result.winner = String(results[4] == undefined ? "" : results[4].value);
        result.round = Number(results[5].value);

        observer.next(result);
        observer.complete();
      });

    })

    return gameObservable;
  }



}
