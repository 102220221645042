<div class="row  mt-5">
    <div class="col col-12 game-container">

        <div [@hideBanner]=" hideBannerAnimation ? 'banner-hidden' : 'banner-show'"
            (@hideBanner.done)=" bannerHiddenDone($event)">

            <div class="leftToRight animated" [@showWinner]=" showWinnerAnimation ? 'ltr-expanded' : 'ltr-shrinked'"></div>
            <div class="rightToLeft animated" [@showWinner]=" showWinnerAnimation ? 'rtl-expanded' : 'rtl-shrinked'"></div>

            <div class="trophy animated" [style.display]="showWinnerAnimation ? 'flex':'none'">
                <svg [@showTrophy]="showWinnerAnimation ? 'trophy-expanded' : 'trophy-shrinked'">
                    <!-- [@doShaking]="showWinnerAnimation ? 'trophy-shaking' : 'trophy-calm'" -->
                    <use xlink:href=" #trophy" />
                </svg>
            </div>
            <div class="winnerText animated" [style.display]="showWinnerAnimation ? 'flex':'none'"
                [@showWinnerText]=" showWinnerAnimation ? 'winnerText-expanded' : 'winnerText-shrinked'"
                (@showWinnerText.done)="winnerTextShown($event)">
                <p class="winnerTitle">We have a winner!</p>
                <p class="winnerDetail">{{game.jackpot}} TAU were transfered to <br><a target="_blank"
                        href="https://mainnet.lamden.io/addresses/{{winnerAccount}}">{{winnerAccount}}</a></p>
                <a class="btn btn-primary" *ngIf="winnerTxHash != undefined" target="_blank" [href]="getWinnerTransactionUrl()">
                    <svg width="32" height="32" style="fill: black; margin-right: 10px;">
                        <use xlink:href="#moneybag" />
                    </svg>
                    Show Transaction
                </a>
            </div>

        </div>


        <div style="padding: 10px;">
            <div class="row align-items-center">
                <div class="col col-6 col-md-3">
                    <div class="row">
                        <div class="d-flex flex-column justify-content-end align-items-center">
                            <div class="keynumber">{{game.jackpot}}</div>
                            <div class="keytext">TAU in Jackpot</div>
                        </div>
                    </div>
                    <!-- <svg width="32" height="32" style="fill: silver; margin-right: 10px;">
                    <use xlink:href="#locked-money" />
                </svg> -->
                </div>
                <div class="col col-6 col-md-3">
                    <div class="row">
                        <div class="d-flex flex-column justify-content-end align-items-center">
                            <div class="keynumber">{{game.playerCount}}</div>
                            <div class="keytext">Seats</div>
                        </div>
                    </div>
                    <!-- <svg width="32" height="32" style="fill: silver; margin-right: 10px;">
                    <use xlink:href="#locked-money" />
                </svg> -->
                </div>
                <div class="col col-6 mt-2 col-md-3 mt-md-0">
                    <!-- <svg width="32" height="32" style="fill: silver; margin-right: 10px;">
                    <use xlink:href="#locked-money" />
                </svg> -->
                    <div class="d-flex flex-column justify-content-end align-items-center">
                        <div class="keynumber">{{game.amount}}</div>
                        <div class="keytext">TAU per seat</div>
                    </div>
                </div>
                <div class="col col-6 mt-2 col-md-3 mt-md-0">
                    <div class="d-flex flex-column justify-content-end align-items-center">
                        <div class="keynumber">{{ 1 / game.playerCount | percent }}</div>
                        <div class="keytext">chance of winning</div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col col-12 d-flex" style="justify-content: center;">
                    {{ game.playerCount - game.players.length }} seats left
                </div>
            </div>
            <div class="row">
                <div class="col col-12">
                    <div class="progress">
                        <div class="progress-bar bg-warning" role="progressbar"
                            [style.width]="(100 / game.playerCount * game.players.length) + '%'"
                            [attr.aria-valuenow]="100 / game.playerCount * game.players.length" aria-valuemin="0"
                            aria-valuemax="100">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-12 mt-3" style="text-align: center;">
                    <button type="button" class="btn btn-primary" style="margin: 2px;" (click)="joinGame()">
                        <svg class="bi" width="24" height="24" fill="currentColor">
                            <use xlink:href="#bet" />
                        </svg>
                        Join</button>
                    <button type="button" class="btn btn-success" style="margin: 2px;" *ngIf="hasStake()"
                        (click)="withdrawStake()">
                        <svg class="bi" width="24" height="24" fill="currentColor">
                            <use xlink:href="#withdraw" />
                        </svg>
                        Withdraw stake</button>
                </div>

            </div>
        </div>

    </div>

</div>

<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 490.766 490.766" style="enable-background:new 0 0 490.766 490.766;" xml:space="preserve" display="none">
    <symbol viewBox="0 0 490.766 490.766" id="withdraw">
        <g>
            <path d="M472.554,319.199c-9.4-0.7-28.8,9.8-34.1,12.5c-18.9,9.5-45.2,24.4-62.5,36.3c-1.2,0.8-2.8,1.9-4,2.7
		c-7.8,5-16,8.3-25.1,10.3c-50.2,10.7-123.3,11.8-123.3,11.8l-4.3,0.2c-3.2,0.2-6.1-2.1-6.5-5.2c-0.5-3.4,2-6.6,5.6-6.9l108.7-10.3
		c12-1.3,21.5-12.5,20.2-25.1s-12.9-21.8-24.9-20.5l-96.7-1.2c-6.6-0.1-17.1-1.7-23.4-3.6c-62.3-18.2-97.5-1.2-116.2,6.8
		c-4.2-6.9-12.1-11.4-20.9-10.8l-41.7,2.8c-13.9,0.9-24.3,12.5-23.4,25.9l6.7,92.6c1,13.4,13,23.5,26.9,22.5l41.7-2.8
		c8.8-0.6,16-6,19.1-13.4l183.2,7.9c20.4,2.2,39.5-0.3,57.5-9.7l5.2-2.7l138.5-81c6.8-4,11.6-10.9,11.9-18.5
		C491.054,330.499,485.754,320.199,472.554,319.199z M48.954,438.099c-7.8,0-14.2-6.1-14.2-13.7s6.4-13.7,14.2-13.7
		s14.2,6.1,14.2,13.7S56.754,438.099,48.954,438.099z M367.654,333.399c14.6-8,43.2-21.8,58.8-27.9c7.8-3.1,15.5-2.9,22.6,1.9
		c-5.6,2-10.8,4.5-15.7,6.9c-16.4,8.2-45.3,23.9-59.4,33.8c-1.4,1-2.9,2-4.3,3c0.2-2.5,0.2-5.1-0.1-7.7c-0.3-3.3-1.1-6.4-2.1-9.4
		C367.454,333.799,367.554,333.599,367.654,333.399z M411.354,296.199c-11.3,5-33,12.6-43.3,18.3l-7,3.8c-0.7,0.4-1.3,0.8-1.9,1.3
		c-3.1-3.7-6.8-6.8-10.9-9.3c9.6-5.1,31-12,40.7-15.8C396.754,291.399,404.254,291.599,411.354,296.199z M114.954,232.999
		c0-5.8,4.7-10.5,10.5-10.5h261.7c5.8,0,10.5,4.7,10.5,10.5s-4.7,10.5-10.5,10.5h-261.8
		C119.654,243.499,114.954,238.799,114.954,232.999z M114.954,270.999c0-5.8,4.7-10.5,10.5-10.5h261.7c5.8,0,10.5,4.7,10.5,10.5
		s-4.7,10.5-10.5,10.5h-261.8C119.654,281.499,114.954,276.799,114.954,270.999z M319.554,118.299c0-8.2,6.7-14.9,14.9-14.9
		c8.2,0,14.9,6.7,14.9,14.9s-6.7,14.9-14.9,14.9C326.154,133.199,319.554,126.499,319.554,118.299z M163.254,118.299
		c0-8.2,6.7-14.9,14.9-14.9s14.9,6.7,14.9,14.9s-6.7,14.9-14.9,14.9C169.854,133.199,163.254,126.499,163.254,118.299z
		 M139.254,205.899h234c13.5,0,24.5-11,24.5-24.5v-126.2c0-13.5-11-24.5-24.5-24.5h-234c-13.5,0-24.5,11-24.5,24.5v126.2
		C114.754,194.899,125.754,205.899,139.254,205.899z M135.654,80.599c1.4,0.3,2.9,0.4,4.4,0.4c13.7,0,24.8-11.1,24.8-24.8
		c0-1.6-0.1-3.1-0.4-4.6h182.2c-0.5,1.9-0.8,4-0.8,6.1c0,13.7,11.1,24.8,24.8,24.8c2.1,0,4.2-0.3,6.1-0.8v76.1c-2-0.5-4-0.8-6.1-0.8
		c-13.7,0-24.8,11.1-24.8,24.8c0,1,0.1,2.1,0.2,3.1h-181.6c0.3-1.5,0.4-3,0.4-4.6c0-13.7-11.1-24.8-24.8-24.8
		c-1.5,0-2.9,0.1-4.4,0.4V80.599z M234.954,118.299c0-11.8,9.6-21.5,21.5-21.5c11.8,0,21.5,9.6,21.5,21.5c0,11.8-9.6,21.5-21.5,21.5
		S234.954,130.199,234.954,118.299z M256.454,171.899c29.6,0,53.5-24,53.5-53.5s-24-53.5-53.5-53.5c-29.6,0-53.5,24-53.5,53.5
		S226.854,171.899,256.454,171.899z M256.454,82.399c19.8,0,35.9,16.1,35.9,35.9s-16.1,35.9-35.9,35.9s-35.9-16.1-35.9-35.9
		S236.654,82.399,256.454,82.399z" />
        </g>
    </symbol>
</svg>

<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="124.984px" height="124.984px" viewBox="0 0 124.984 124.984" style="enable-background:new 0 0 124.984 124.984;"
    xml:space="preserve" display="none">
    <symbol viewBox="0 0 124.984 124.984" id="bet">
        <g>
            <g>
                <path d="M105.625,87.618c2.568-5.21,2.736-11.492-1.707-15.548c2.006-5.902,0.354-13.309-5.609-15.5
			c1.062-3.636,1.342-7.725-0.587-10.992c-1.741-2.949-5.287-3.719-8.497-3.634c3.49-7.532,8.768-16.931,15.723-23.692l0.978-0.949
			l-0.822-1.087c-0.562-0.744-13.967-18.163-31.008-12.89L72.919,3.69l0.093,1.227c0.024,0.31,2.218,30.509-4.166,43.979
			c-0.578,0.309-1.153,0.618-1.722,0.927c-0.932-2.884-2.075-5.787-3.441-8.569c-2.778-6.436-7.078-14.921-12.894-22.191
			c2.744-2.411,1.345-6.371,0.307-8.458c3.642-3.166,9.018-6.734,15.224-7.383c0.61,1.704,2.032,4.724,4.577,5.597
			c-0.095-2.248-0.194-3.623-0.2-3.687L70.512,2.68l2.349-0.728c0.912-0.282,1.834-0.492,2.762-0.663l-0.961-0.297
			C57.619-4.28,44.215,13.138,43.653,13.882l-0.822,1.087l0.976,0.95c7.411,7.205,12.921,17.407,16.389,25.152
			c-10.391,3.558-14.177,12.531-17.517,20.448c-2.32,5.501-4.35,10.301-8.111,12.136l-0.125-0.381l-1.16-3.562l-16.858,5.492
			l16.218,49.779l16.859-5.492l-0.975-2.993c15.287-4.436,31.205-6.159,46.669-9.845c4.75-1.132,9.687-2.249,12.308-6.694
			C109.734,96.184,108.106,91.034,105.625,87.618z M25.604,83.219l-1.605-4.927l4.924-1.604l1.605,4.925L25.604,83.219z
			 M75.746,32.917c0.451,1.879,2.025,3.732,4.324,4.798c3.457,1.601,7.227,0.813,8.418-1.763c1.194-2.574-0.642-5.96-4.098-7.562
			c-3.423-1.585-7.148-0.824-8.38,1.691c0.571-6.966,0.548-13.837,0.403-18.679c3.471-0.009,5.304-3.843,6.022-5.847
			c6.205,0.649,11.581,4.218,15.224,7.383c-1.039,2.087-2.438,6.046,0.307,8.458c-5.45,6.813-9.564,14.686-12.346,20.947
			c-4.325,0.763-8.569,2.453-12.633,4.429C74.352,42.695,75.219,37.854,75.746,32.917z M60.424,41.577
			c-0.008,0.011-0.015,0.021-0.023,0.032l-0.117-0.343C60.331,41.37,60.379,41.476,60.424,41.577z M100.869,98.708
			c-4.021,2.49-9.832,3.179-14.367,4.098c-12.852,2.606-26.941,4.316-39.551,7.984L36.474,79.437
			c5.846-2.435,9.388-9.26,11.933-15.295c2.872-6.809,5.852-13.832,13.292-16.653c0.928,2.251,1.545,4.396,2.291,7.016
			c0.396,1.393,1.996,1.717,3.135,1.094c6.005-3.281,12.513-6.824,19.359-8.003c0.049,0.017,0.1,0.028,0.148,0.047
			c0.012-0.026,0.023-0.054,0.033-0.08c0.115-0.019,0.228-0.043,0.343-0.06c2.073-0.319,5.433-0.531,6.489,1.806
			c0.791,1.748,0.513,3.83,0.141,5.644c-0.219,1.066-0.734,2.67-0.84,2.931c-0.462,1.135-0.02,2.815,1.417,2.986
			c5.495,0.651,6.28,6.889,4.21,11.146c-0.376,0.772-0.292,1.964,0.473,2.505c4.348,3.07,3.627,7.937,1.211,12.087
			c-0.334,0.572-0.332,1.576,0.118,2.097C102.609,91.461,104.833,96.253,100.869,98.708z" />
                <path d="M64.365,26.057c-3.456,1.602-5.292,4.988-4.098,7.562c1.192,2.575,4.961,3.364,8.418,1.763
			c0.525-0.243,1.006-0.532,1.452-0.849c0.356-2.846,0.589-5.781,0.733-8.646C69.143,24.988,66.686,24.982,64.365,26.057z" />
            </g>
        </g>
    </symbol>
</svg>

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 472.615 472.615" style="enable-background:new 0 0 472.615 472.615;" xml:space="preserve" display="none">
    <symbol viewBox="0 0 472.615 472.615" id="trophy">
        <g>
            <g>
                <path d="M382.487,373.864h-50.366c-8.376,11.406-21.777,18.91-36.983,18.91H177.45c-15.204,0-28.604-7.504-36.979-18.91h-50.37
			c-10.443,0-18.909,8.465-18.909,18.91v63.422h330.206v-63.422C401.398,382.329,392.932,373.864,382.487,373.864z" />
            </g>
        </g>
        <g>
            <g>
                <path d="M467.154,72.184c-7.991-16.241-19.339-25.946-33.729-28.844c-15.474-3.174-31.015,2.636-42.287,8.829
			c0.25-11.53,0.403-23.367,0.453-35.749h-0.733H81.878h-0.751c0.05,12.41,0.203,24.27,0.454,35.825
			C70.298,46.036,54.705,40.179,39.19,43.34C24.8,46.239,13.452,55.943,5.461,72.184c-7.853,15.965-7.225,34.699,1.81,54.19
			c17.333,37.363,64.203,73.604,113.828,88.862c14.113,16.368,33.045,28.82,58.931,36.8c15.179,4.679,26.952,18.023,27.198,33.904
			c0.17,10.916-4.762,23.176-21.523,33.775h-8.257c-14.951,0-27.073,12.124-27.073,27.075c0,14.95,12.122,27.074,27.073,27.074
			h117.69c14.952,0,27.074-12.124,27.074-27.074v-0.003c0-14.95-12.121-27.072-27.072-27.072h-8.129
			c-16.762-10.599-21.692-22.859-21.522-33.775c0.245-15.881,12.018-29.225,27.198-33.904c25.906-7.987,44.847-20.449,58.964-36.839
			c49.575-15.283,96.375-51.491,113.693-88.822C474.379,106.883,475.008,88.149,467.154,72.184z M24.426,118.416
			c-6.643-14.33-7.318-27.072-1.994-37.884C27.76,69.701,34.453,63.597,42.888,61.88c13.951-2.821,30.856,6.888,39.427,13.192
			c1.974,45.684,7.324,83.739,21.434,113.372C68.04,172.264,37.048,145.633,24.426,118.416z M448.189,118.416
			c-12.606,27.182-43.537,53.784-79.189,69.968c14.096-29.639,19.435-67.701,21.406-113.383c8.602-6.305,25.418-15.922,39.322-13.12
			c8.434,1.717,15.129,7.821,20.456,18.652C455.506,91.344,454.833,104.086,448.189,118.416z" />
            </g>
        </g>
    </symbol>
</svg>


<svg xmlns="http://www.w3.org/2000/svg" display="none">
    <symbol viewBox="0 0 485.5 485.5" id="locked-money">
        <path
            d="M138.2 198.6c0 47.6 38.6 86.2 86.2 86.2s86.2-38.6 86.2-86.2 -38.6-86.2-86.2-86.2C176.8 112.4 138.2 151 138.2 198.6zM232 147c0 5.2 0 5.3 5.2 6.1 4 0.6 7.8 1.8 11.5 3.4 2 0.9 2.8 2.3 2.2 4.5 -0.9 3.2-1.8 6.4-2.8 9.6 -1 3-1.9 3.4-4.8 2 -5.8-2.8-11.9-4-18.2-3.6 -1.7 0.1-3.3 0.3-4.9 1 -5.5 2.4-6.4 8.5-1.7 12.3 2.4 1.9 5.1 3.3 7.9 4.5 4.9 2 9.8 4 14.5 6.5 14.8 8.2 18.8 26.8 8.4 39.4 -3.8 4.6-8.7 7.7-14.4 9.2 -2.5 0.7-3.6 2-3.5 4.6 0.1 2.5 0 5.1 0 7.6 0 2.3-1.2 3.5-3.4 3.5 -2.7 0.1-5.5 0.1-8.2 0 -2.4 0-3.5-1.4-3.6-3.8 0-1.9 0-3.7-0.1-5.6 0-4.1-0.2-4.3-4.1-4.9 -5.1-0.8-10-2-14.6-4.2 -3.6-1.8-4-2.7-3-6.5 0.8-2.8 1.6-5.7 2.4-8.5 1-3.3 1.9-3.7 4.9-2.1 5.1 2.7 10.6 4.2 16.2 4.9 3.7 0.5 7.3 0.1 10.7-1.4 6.3-2.8 7.3-10.1 2-14.5 -1.8-1.5-3.9-2.6-6.1-3.6 -5.6-2.4-11.4-4.3-16.6-7.5 -8.5-5.1-13.9-12.1-13.3-22.5 0.7-11.7 7.3-19 18.1-22.9 4.4-1.6 4.5-1.5 4.5-6.2 0-1.6 0-3.1 0-4.7 0.1-3.5 0.7-4.1 4.2-4.2 1.1 0 2.2 0 3.2 0C232 139.6 232 139.6 232 147z" />
        <circle cx="349.9" cy="203" r="23.9" />
        <circle cx="99" cy="203" r="23.9" />
        <path
            d="M414.9 58H39.4C17.7 58 0 75.7 0 97.4V300c0 21.7 17.7 39.4 39.4 39.4h273.1v-12.9c0-7.7 2.3-14.8 6.2-20.7H83c0.2-1.6 0.3-3.3 0.3-4.9 0-22-17.8-39.9-39.9-39.9 -3.4 0-6.7 0.4-9.9 1.2V139.9c3.2 0.8 6.5 1.2 9.9 1.2 22 0 39.9-17.8 39.9-39.9 0-3.4-0.4-6.6-1.2-9.7h292.4c-0.4 2.4-0.7 4.8-0.7 7.3 0 22 17.8 39.9 39.9 39.9 2.4 0 4.7-0.2 7-0.6v71.6c12.3 1.5 23.7 5.7 33.6 12.1V97.3C454.3 75.6 436.6 58 414.9 58z" />
        <path
            d="M410.7 231.4c-32.5 0-58.9 26.4-58.9 58.9v20.5h-1.4c-8.6 0-15.5 7-15.5 15.5V412c0 8.6 7 15.5 15.5 15.5H470c8.6 0 15.5-7 15.5-15.5v-85.7c0-8.6-7-15.5-15.5-15.5h-0.4v-20.5C469.7 257.9 443.2 231.4 410.7 231.4zM419.5 373.1c-1.2 0.9-1.5 1.7-1.5 3.1 0 6.3 0 12.6 0 18.9l0 0c0.1 2.6-1.2 5.2-3.5 6.4 -5.5 2.8-11-1.1-11-6.3l0 0c0-6.3 0-12.6 0-18.9 0-1.3-0.3-2.1-1.4-3 -5.7-4.2-7.6-11.4-4.7-17.7 2.8-6.2 9.5-9.7 15.9-8.5 7.1 1.4 12 7.2 12.1 14.2C425.4 366.2 423.4 370.2 419.5 373.1zM443.9 310.8h-66.3v-20.5c0-18.3 14.9-33.1 33.1-33.1 18.3 0 33.1 14.9 33.1 33.1v20.5H443.9z" />
    </symbol>

</svg>


<svg xmlns="http://www.w3.org/2000/svg" display="none">
    <symbol viewBox="0 0 485.5 485.5" id="users">
        <path
            d="M136.5 105.8c-42.1 0-76.3 34.1-76.3 76.3s34.1 76.3 76.3 76.3 76.3-34.1 76.3-76.3S178.6 105.8 136.5 105.8zM136.5 157.2c-13.7 0-24.9 11.1-24.9 24.9 0 6.3-5.1 11.4-11.4 11.4s-11.4-5.1-11.4-11.4c0-26.3 21.4-47.6 47.6-47.6 6.3 0 11.4 5.1 11.4 11.4S142.7 157.2 136.5 157.2z" />
        <path
            d="M349.1 53.8c-42.1 0-76.3 34.1-76.3 76.3s34.1 76.3 76.3 76.3 76.3-34.1 76.3-76.3S391.3 53.8 349.1 53.8zM349.1 105.2c-13.7 0-24.9 11.1-24.9 24.9 0 6.3-5.1 11.4-11.4 11.4s-11.4-5.1-11.4-11.4c0-26.3 21.4-47.6 47.6-47.6 6.3 0 11.4 5.1 11.4 11.4C360.5 100.1 355.4 105.2 349.1 105.2z" />
        <path
            d="M485.5 309.1v-1.6c0-0.1 0-0.2 0-0.2 -0.7-21.5-4.7-36.3-26.7-50.1 -29.1-18.3-61.6-34.1-61.6-34.1l-25.9 81.8 -15.1-30.4c26.5-37-1.9-38.9-6.9-38.9l0 0c0 0-0.1 0-0.2 0s-0.1 0-0.2 0l0 0c-5.1 0-33.5 1.9-6.9 38.9l-15.1 30.4 -25.9-81.8c0 0-32.5 15.8-61.6 34.1 -6.7 4.2-11.7 8.5-15.5 13.1 10.5 5.7 22.5 12.6 34.1 19.9 14.1 8.9 23.8 19.2 29.7 31.6 5.8 12.3 7.1 24.9 7.4 35.7 0.1 0.7 0.1 1.4 0.1 2.1v1.3c0.1 4.4 0.1 8.9 0 13.5 0 0.3 0 0.5 0 0.8v4.6h53.7 136.5v-59.2c0 1 0 1.6 0 1.6C485.6 317.6 485.6 313.2 485.5 309.1zM485.3 306.9C485.3 306.5 485.3 306.5 485.3 306.9L485.3 306.9z" />
        <path
            d="M272.8 361.1v-1.6c0-0.1 0-0.2 0-0.2 -0.6-21.5-4.7-36.3-26.7-50.1 -29.1-18.3-61.6-34.1-61.6-34.1l-25.9 81.8 -15.1-30.4c26.5-37-1.9-38.9-6.9-38.9l0 0c0 0-0.1 0-0.2 0s-0.1 0-0.2 0l0 0c-5.1 0-33.5 1.9-6.9 38.9l-15.1 30.4 -25.9-81.8c0 0-32.5 15.8-61.6 34.1 -21.9 13.8-25.9 28.6-26.6 50.1 0 0.1 0 0.1 0 0.2v1.6c-0.1 4.1-0.1 8.5 0 13.1 0 0 0-0.6 0-1.6v59.2h136.2 136.5v-59.2c0 1 0 1.6 0 1.6C272.9 369.6 272.9 365.2 272.8 361.1z" />
        <path d="M0.3 358.9C0.3 358.5 0.3 358.5 0.3 358.9 0.3 359 0.3 358.9 0.3 358.9z" />
        <path d="M272.6 359C272.6 358.5 272.6 358.5 272.6 359 272.6 358.9 272.6 359 272.6 359z" />
    </symbol>
</svg>

<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 489.866 489.866" style="enable-background:new 0 0 489.866 489.866;" xml:space="preserve" display="none">
    <symbol viewBox="0 0 489.866 489.866" id="moneybag">

        <g>
            <g>
                <path d="M179.733,95.266h129.2c25.8-33.5,26.8-58.6,27.2-60.1c2.7-21.7-17.1-38.8-38.4-34.5c-16.3,3.9-34.1,5.8-53.1,5.8
			s-36.9-1.9-53.1-5.4c-21.3-4.7-41.5,12.8-38.8,34.1C153.033,37.066,157.133,69.666,179.733,95.266z" />
                <path d="M314.733,116.366h-140.4c-59.4,45.8-100.9,142.4-100.9,223.4c0,110.6,76.8,150.1,171.5,150.1s171.5-40,171.5-150.1
			C416.033,258.766,374.533,162.166,314.733,116.366z M260.133,391.866v10.5c0,8.4-6.8,15.2-15.2,15.2c-8.4,0-15.2-6.8-15.2-15.2
			v-10.5c-8.9-2.7-17-7.6-23.5-14.5c-5.7-6.1-5.5-15.7,0.7-21.5c6.1-5.7,15.7-5.5,21.5,0.7c4.3,4.6,10.2,7.2,16.6,7.2
			c12.5,0,22.7-10.2,22.7-22.7s-10.2-22.7-22.7-22.7c-29.3,0-53.1-23.8-53.1-53.1c0-24,16-44.3,37.9-50.9v-10.5
			c0-8.4,6.8-15.2,15.2-15.2c8.4,0,15.2,6.8,15.2,15.2v10.5c8.9,2.7,17,7.6,23.5,14.5c5.7,6.1,5.5,15.7-0.6,21.5
			c-6.1,5.7-15.7,5.5-21.5-0.6c-4.3-4.6-10.2-7.2-16.6-7.2c-12.5,0-22.7,10.2-22.7,22.7s10.2,22.7,22.7,22.7
			c29.3,0,53.1,23.8,53.1,53.1C298.033,365.066,282.033,385.366,260.133,391.866z" />
            </g>
        </g>
    </symbol>
</svg>