<div class="container">
    <div class="row">
        <div class="col col-12">
            <h1>Winners</h1>
        </div>
    </div>
    <div class="row">
        <div class="col col-10 offset-1">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Amount (TAU)</th>
                        <th scope="col">Winner</th>
                        <th scope="col">Transaction</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let winner of (winners | async) ">
                        <td>{{winner.date.seconds * 1000 | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>{{winner.amount}}</td>
                        <td><a target="_blank"
                                href="https://www.tauhq.com/addresses/{{winner.winner}}">{{winner.winner | truncate:30}} </a>
                        </td>
                        <td><a target="_blank"
                                href="https://www.tauhq.com/transactions/{{winner.txHash}}">{{winner.txHash | truncate:30}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
