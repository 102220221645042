import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AstTransformer } from '@angular/compiler/src/output/output_ast';
import { WinnerModel } from '../game/winner.model';
import { ObservableLike, Observable } from 'rxjs';
import { DatabaseService } from '../database.service';



@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.scss']
})
export class WinnersComponent implements OnInit {

  winners: Observable<WinnerModel[]>

  constructor(
    private firestore: AngularFirestore,
    private databaseService: DatabaseService) { }

  ngOnInit(): void {
    this.winners = this.databaseService.getWinners();
    console.log(this.winners)
  }

}
