import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  isCollapsed: boolean = true;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  navigateToFaq() {
    this.router.navigate(["/faq"]);
    this.isCollapsed = true;
  }

  navigateToWinners() {
    this.router.navigate(["/winners"]);
    this.isCollapsed = true;
  }

}
