import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import WalletController from './WalletController'
import { approvalRequest, baseURL } from './wallet-approval.js';
import { DOCUMENT } from '@angular/common';
import { WalletService } from '../wallet.service';
import { DatabaseService } from '../database.service';
import { BalanceModel } from './balance.model';

@Component({
  selector: 'app-wallet-button',
  templateUrl: './wallet-button.component.html',
  styleUrls: ['./wallet-button.component.scss']
})
export class WalletButtonComponent implements OnInit {

  walletInfo: any;


  account: string;
  approvedAmount: number;
  accountBalance: number;
  installed: boolean;
  locked: boolean;
  setup: boolean;
  authorized: boolean;
  unlockingCaption: string = "Yes, i unlocked the wallet";
  unlockingDisabled: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: HTMLDocument,
    private walletService: WalletService,
    private databaseService: DatabaseService) { }

  connectWallet(): void {
    this.walletService.walletController.sendConnection();
  }

  unlockWallet(): void {
    this.walletService.walletController.sendConnection().then(response => {
      if (this.locked) {
        let savedCaption = this.unlockingCaption;
        this.unlockingCaption = "hmm, try again...";
        this.unlockingDisabled = true;
        setTimeout((handler) => {
          this.unlockingCaption = savedCaption;
          this.unlockingDisabled = false;
        }, 2000)
      }
    });
  }

  ngOnInit(): void {

    this.walletService.walletController.events.on('newInfo', (info: any) => {
      //autoTx.set(lwc.autoTransactions)
      //userAccount.set(lwc.walletAddress)
      this.walletInfo = info;
    })

    this.walletService.walletController.events.on('txStatus', (results) => {
      let errors = this.processTxResults(results)
      if (errors.length > 0) {
        if (errors[0].includes('have not been approved for')) this.walletService.walletController.sendConnection(approvalRequest, true)
      }
    })

    this.walletService.balanceSubject.subscribe((balanceModel: BalanceModel) => {
      this.approvedAmount = balanceModel.approved;
      this.accountBalance = balanceModel.balance;
    })


    this.walletService.walletController.events.on('newInfo', (data: any) => {
      if (typeof data.installed !== 'undefined') this.installed = data.installed
      if (typeof data.locked !== 'undefined') this.locked = data.locked
      if (typeof data.setup !== 'undefined') this.setup = data.setup;
      if (typeof data.authorized !== 'undefined') this.authorized = data.authorized;
      if (this.setup === true && data.wallets != 'undefined' && data.wallets.length > 0) {
        this.account = data.wallets[0];
        console.log(data)
        this.walletService.setAccount(this.account);
        this.updateBalance();
      }
    });

    this.walletService.walletController.walletIsInstalled().then(
      (val) => {
        if (val === true) {
          this.walletService.walletController.getInfo();
          if (this.walletInfo != undefined && this.walletInfo.setup === true) {
            this.walletService.walletController.sendConnection();
          }
        } else {
          this.installed = false;
        }


      },
      (err) => console.log(err));

    //You must be an authorized dApp to send this message type. Send 'lamdenWalletConnect' event first to authorize.
    setTimeout(() => {
      this.walletService.walletController.getInfo();
    }, 300)


  }

  updateBalance() {
    this.walletService.updateBalance();
  }

  onApprovalSubmit() {

  }

  processTxResults(results: any): any[] {
    if (results.data) {
      if (results.data.errors) return results.data.errors
      if (results.data.resultInfo) {
        //createSnack({
        //    title: results.data.resultInfo.title,
        //    body: results.data.resultInfo.subtitle,
        //    type: results.data.resultInfo.type
        console.log(JSON.stringify(results.data.resultInfo))


        //refreshTAUBalance(get(userAccount))
      }
    }
    return []
  }

  /* refreshTAUBalance = async (account) => {
    const res = await fetch(`${config.masternode}/contracts/currency/balances?key=${account}`)
    const data = await res.json();
    if (!data.value) currency.set(0)
    else currency.set(data.value);
  } */

}
