<div class="container">
      <div class="row justify-content-md-center">
            <div class="col col-12">
                  <app-wallet-button class="full-width"></app-wallet-button>
            </div>
      </div>

      <!-- <div class="row mt-5">
      <div class="col col-12 flex-centered distributed">
            <a class="btn btn-success"
                  href="https://chrome.google.com/webstore/detail/lamden-wallet-browser-ext/fhfffofbcgbjjojdnpcfompojdjjhdim"
                  target="_blank" role="button">
                  <svg width="32" height="32" style="fill: var(--secondary); margin-right: 10px;">
                        <use xlink:href=" #winner" />
                  </svg>
                  See all transactions of previous winners</a>
      </div>
</div> -->

      <!-- Modal -->
      <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">

            <div class="modal-dialog">
                  <div class="modal-content">
                        <div class="modal-header">
                              <h5 class="modal-title" id="staticBackdropLabel">Not enough coins approved</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                              </button>
                        </div>
                        <div class="modal-body">
                              <p>
                                    You dont have enough coins approved!<br>
                                    You want to play with <span style="font-weight: 600;"> <span id="price"></span> TAU </span>
                                    but you have only <span style="font-weight: 600;"> <span id="approved"></span> TAU
                                    </span>approved
                              </p>
                              <p>
                                    <span class="important">The chosen amount won't be transferred and stays in your
                                          account</span>
                              </p>


                              <div style="text-align: center; font-weight: 600;">
                                    <span>Approve</span>&nbsp;
                                    <input type="text" placeholder="100" size="6" #approvalAmountInput>&nbsp;TAU
                              </div>

                        </div>
                        <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                              <button type="button" class="btn btn-primary" data-dismiss="modal"
                                    (click)="approve()">Approve</button>
                        </div>
                  </div>
            </div>
      </div>

      <div class="row justify-content-md-center">
            <div class="col col-11 col-lg-9">
                  <app-game *ngFor="let game of games" [game]="game"></app-game>
            </div>
      </div>


      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 495.704 495.704" style="enable-background:new 0 0 495.704 495.704;" xml:space="preserve" display="none">
            <symbol viewBox="0 0 495.704 495.704" id="winner">
                  <g id="XMLID_550_">
                        <path id="XMLID_551_" d="M218.174,282.702c0,16.357,13.299,29.667,29.65,29.667c16.366,0,29.689-13.31,29.689-29.667
		c0-16.391-13.323-29.714-29.689-29.714C231.473,252.989,218.174,266.312,218.174,282.702z M247.825,297.888
		c-8.357,0-15.155-6.822-15.155-15.186c0-8.379,6.798-15.219,15.155-15.219c8.378,0,15.193,6.84,15.193,15.219
		C263.018,291.066,256.203,297.888,247.825,297.888z" />
                        <polygon id="XMLID_554_" points="247.839,232.167 270.001,169.816 225.678,169.816 	" />
                        <path id="XMLID_555_" d="M279.207,433.749c-6.983-11.143-16.325-44.822,2.257-59.512c10.322-8.025,22.208-6.661,34.417-2.166
		c0.306-15.315,8.147-64.68,21.592-75.644v-0.401l0.16,0.258c3.421-2.746,5.418-3.372,9.441-4.994
		c5.24-0.385,6.733-0.818,11.798,0.515c-4.67-30.388-4.246-45.85,5.868-54.983l-29.892-49.042
		c-6.967-11.397-17.714-17.964-34.097-17.964L274.85,242.73c12.786,8.686,21.199,23.342,21.199,39.957
		c0,26.648-21.583,48.224-48.24,48.224c-26.577,0-48.16-21.575-48.16-48.224c0-16.615,8.411-31.272,21.19-39.957l-25.902-72.914
		c-16.439,0-27.194,6.663-34.097,17.964l-29.859,48.994c10.146,8.909,10.571,24.37,5.852,55.031
		c4.254-1.125,5.899-0.931,10.266-0.931c4.71,1.732,7.264,2.327,11.044,5.28l0.081-0.128v0.191
		c13.524,10.741,21.255,59.414,21.599,75.854c12.064-4.415,24.064-5.893,34.291,2.071c18.71,14.784,9.367,48.464,2.536,59.381
		c-2.834,4.398-6.726,7.865-11.245,10.29h84.874C285.789,441.405,281.952,438.017,279.207,433.749z" />
                        <path id="XMLID_556_" d="M247.864,155.609c40.823,0,73.926-33.101,73.926-73.957c0-40.808-33.103-73.91-73.926-73.91
		c-40.848,0-73.933,33.103-73.933,73.91C173.931,122.508,207.016,155.609,247.864,155.609z" />
                        <path id="XMLID_557_" d="M129.535,386.631c-15.499-21.816-15.186-21.462-29.394-44.403c-13.533-24.994-28.198-55.224-34.916-83.316
		c-6.125-25.732-7.384-52.863-6.309-79.287c1.381-26.762,4.358-48.111,7.192-61.115c0,0-9.616,23.792-14.054,60.409
		c-3.203,26.664-2.866,53.553,1.316,79.864c4.696,29.748,17.033,64.534,29.321,92.322c12.754,25.382,15.371,28.223,27.836,50.457
		c8.147,12.697,24.112,33.436,32.5,43.216c-24.081-7.804-27.949,29.762-15.275,39.683c11.654,9.118,33.078-1.685,37.244-8.395
		c4.302-6.646-0.86-17.628-10.683-25.397c3.619,0.096,7.289-1.14,10.137-3.821c5.121-4.768,6.429-14.143,0.722-20.275
		C126.188,384.864,139.889,400.244,129.535,386.631z" />
                        <path id="XMLID_558_" d="M73.63,107.159c7.44,2.73,16.535-3.355,21.271-13.389c4.093-10.082,14.287-39.072,8.299-43.649
		c-6.814-4.849-29.45,19.875-34.233,31.498C63.66,92.888,66.213,104.432,73.63,107.159z" />
                        <path id="XMLID_559_" d="M77.764,129.555c-1.549,0.85-2.955,1.7-4.005,2.617c-7.674,6.003-10.724,19.023-6.542,27.706
		c0.755,1.573,1.822,2.617,2.85,3.725c4.671,4.865,11.559,4.93,17.08-0.097c6.526-6.582,22.33-26.856,19.673-35.075
		C104.395,120.869,87.339,124.322,77.764,129.555z" />
                        <path id="XMLID_560_" d="M202.256,389.376c-7.161-5.57-26.729,4.576-32.25,10.273c-5.402,5.748-3.196,16.584,5.747,23.552
		c8.901,6.998,20.219,6.726,24.513,0.065C204.472,416.556,209.392,395.028,202.256,389.376z" />
                        <path id="XMLID_561_" d="M89.378,236.197c4.078-8.364,20.268-40.857,15.997-47.324c-5.057-7.771-32.355,13.436-37.613,21.912
		C54.638,228.506,76.872,255.829,89.378,236.197z" />
                        <path id="XMLID_562_" d="M82.668,286.33c-3.893,10.163,1.091,24.159,10.531,29.57c9.439,5.457,18.79,0.626,21.591-9.15
		c1.614-10.597,9.672-50.424,3.099-55.85C109.894,244.03,85.742,275.174,82.668,286.33z" />
                        <path id="XMLID_563_" d="M140.572,389.681c12.394,2.617,20.62-6.115,19.995-17.77c-2.514-12.249-7.113-57.81-15.717-61.308
		c-10.988-4.896-27.323,36.826-26.576,50.279C117.559,373.355,128.203,387.129,140.572,389.681z" />
                        <path id="XMLID_564_" d="M48.796,155.982c6.919-7.177,9.873-19.714,6.902-29.314c-3.29-9.246-15.828-31.095-24.104-24.432
		c-8.934,7.096-7.264,40.694-2.737,50.087C32.856,162.082,41.885,163.094,48.796,155.982z" />
                        <path id="XMLID_565_" d="M43.048,240.916c4.648-8.83,3.965-21.721-1.597-30.085c-6.125-7.432-29.184-39.072-35.357-30.886
		c-7.48,9.503,9.319,55.704,16.808,63.042C29.459,251.255,38.416,249.728,43.048,240.916z" />
                        <path id="XMLID_566_" d="M40.07,338.391c10.178,7.159,19.528,2.345,21.6-9.135c2.063-11.509-2.97-25.716-11.847-33.295
		c-9.295-6.275-44.885-34.948-49.204-23.806C-4.983,285.367,28.993,332.74,40.07,338.391z" />
                        <path id="XMLID_567_" d="M88.712,380.74c-12.425-3.549-61.043-23.952-61.998-10.21c-1.926,16.776,52.615,58.368,67.223,60.553
		c13.99,4.366,22.218-4.384,20.019-17.755C111.732,399.907,101.064,386.117,88.712,380.74z" />
                        <path id="XMLID_568_"
                              d="M352.676,444.778c25.156-29.314,42.277-57.728,60.337-93.673c12.039-27.211,24.513-61.869,29.322-92.322
		c3.892-24.481,4.118-47.276,2.559-67.166c-2.247-29.168-9.317-58.307-15.297-73.107c3.587,16.438,7.24,45.496,7.504,73.396
		c0.258,22.235-1.476,45.384-6.621,67.023c-7.265,30.468-21.688,58.852-34.916,83.3c-32.805,52.943-36.914,54.262-65.024,84.344
		c-5.716,6.15-4.399,15.523,0.715,20.275c2.849,2.682,6.516,3.917,10.137,3.821c-9.825,7.769-14.986,18.751-10.683,25.397
		c4.166,6.71,25.588,17.514,37.243,8.395c11.638-9.119,6.012-32.251,0.53-37.918C365.053,442.898,359.073,442.706,352.676,444.778z" />
                        <path id="XMLID_569_" d="M422.075,107.159c7.415-2.728,9.968-14.271,4.663-25.54c-4.786-11.623-27.419-36.347-34.235-31.498
		c-5.987,4.577,4.206,33.567,8.3,43.649C405.539,103.804,414.633,109.89,422.075,107.159z" />
                        <path id="XMLID_570_" d="M428.487,159.878c4.182-8.684,1.131-21.703-6.541-27.706c-7.177-6.15-30.181-12.699-33.063-3.741
		c-2.657,8.219,13.147,28.493,19.674,35.075C415.886,170.169,424.907,167.343,428.487,159.878z" />
                        <path id="XMLID_571_" d="M325.699,399.65c-5.523-5.697-25.092-15.844-32.251-10.273c-7.127,5.651-2.216,27.18,1.991,33.89
		c4.293,6.661,15.611,6.934,24.513-0.065C328.892,416.234,331.1,405.398,325.699,399.65z" />
                        <path id="XMLID_572_" d="M390.329,188.872c-4.27,6.468,11.92,38.96,15.998,47.324c4.783,7.512,13.741,9.038,20.153,2.039
		c6.053-6.486,6.671-20.421,1.462-27.451C422.684,202.308,395.385,181.101,390.329,188.872z" />
                        <path id="XMLID_573_" d="M402.513,315.901c9.433-5.411,14.417-19.407,10.524-29.57c-3.075-11.156-27.227-42.3-35.221-35.43
		c-6.574,5.427,1.484,45.254,3.099,55.85C383.716,316.527,393.065,321.358,402.513,315.901z" />
                        <path id="XMLID_574_" d="M350.853,310.603c-8.604,3.498-13.204,49.059-15.715,61.308c-0.626,11.655,7.601,20.388,19.994,17.77
		c12.369-2.552,23.013-16.326,22.297-28.799C378.177,347.429,361.842,305.707,350.853,310.603z" />
                        <path id="XMLID_575_" d="M466.846,153.314c4.525-9.393,6.196-42.991-2.737-50.087c-8.277-6.663-20.815,15.186-24.106,24.432
		c-2.969,9.601-0.015,22.138,6.904,29.314C453.818,164.085,462.848,163.073,466.846,153.314z" />
                        <path id="XMLID_576_" d="M452.655,240.916c4.633,8.811,13.589,10.339,20.148,2.071c7.489-7.338,24.288-53.539,16.808-63.042
		c-6.172-8.186-29.234,23.454-35.357,30.886C448.69,219.196,448.008,232.086,452.655,240.916z" />
                        <path id="XMLID_577_" d="M434.034,329.256c2.071,11.479,11.422,16.294,21.6,9.135c11.076-5.65,45.053-53.023,39.451-66.235
		c-4.318-11.142-39.91,17.53-49.204,23.806C437.004,303.54,431.971,317.747,434.034,329.256z" />
                        <path id="XMLID_578_" d="M406.992,380.74c-12.353,5.377-23.021,19.167-25.244,32.588c-2.198,13.371,6.028,22.121,20.019,17.755
		c14.608-2.185,69.15-43.776,67.224-60.553C468.035,356.788,419.416,377.192,406.992,380.74z" />
                  </g>
            </symbol>
      </svg>
</div>