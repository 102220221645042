import { Component, AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {

  constructor(private renderer: Renderer2) {
    this.renderer.setStyle(document.body, 'background-color', 'var(--secondary)');
  }



}
