export class GameModel {

    id: string;
    playerCount: number;
    amount: number;
    jackpot: number;
    markedForRemoval: boolean;
    winner: string;
    players: string[];
    round: number;

}