import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreModule, QuerySnapshot, DocumentData } from '@angular/fire/firestore';
import { from } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth'
import { WinnerModel } from './game/winner.model';
import { Observable, ObservableLike } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GameModel } from './game/game.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(private db: AngularFirestore,
    private auth: AngularFireAuth,
    private http: HttpClient) { }

  addWinner(txHash: string): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<void>('https://h3f2axs87h.execute-api.us-east-1.amazonaws.com/dev/winner', { "txHash": txHash }, httpOptions);
  }

  getWinners(): Observable<WinnerModel[]> {
    return this.db.collection<WinnerModel>('winners', ref => ref.orderBy('date', 'desc').limit(50)).valueChanges();
  }

  getWinnerTransaction(game: GameModel): Observable<QuerySnapshot<DocumentData>> {
    return this.db.collection<WinnerModel>('winners', ref => ref.where('gameId', '==', game.id)
      .where('round', '==', game.round - 1)
      .where('winner', '==', game.winner))
      .get();
  }
}
