import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ContractService } from '../contract.service';
import { GameModel } from '../game/game.model';
import { approvalRequest } from '../wallet-button/wallet-approval.js';
import { WalletService } from '../wallet.service';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {



  games: GameModel[] = [];

  @ViewChild("approvalAmountInput")
  approvalAmountInput: ElementRef;

  constructor(
    private contractService: ContractService,
    private walletService: WalletService) { }

  ngOnInit(): void {
    this.contractService.getAllGames().subscribe(gameModel => {
      if (gameModel.markedForRemoval === false) {
        this.games.push(gameModel)
      }
    });

    // Game entfernen wenn es markedForRemoval ist
    this.contractService.markedForRemovalSubject.subscribe(gameModel => {
      const gamesToRemove: GameModel[] = this.games.filter(game => game.id === gameModel.id)
      gamesToRemove.forEach(gameToRemove => {
        const index: number = this.games.indexOf(gameToRemove);
        this.games.splice(index, 1)
      })
    });

  }

  approve(): void {

    const transaction = {
      contractName: "currency",
      methodName: 'approve',
      networkType: approvalRequest.networkType,
      networkName: approvalRequest.networkName,
      stampLimit: 100,
      kwargs: {
        amount: new Number(this.approvalAmountInput.nativeElement.value),
        to: approvalRequest.contractName
      }
    }

    const handleApprovalTx = (txResults) => {

      if (txResults.txBlockResult.status === 0) {
        console.log("approval successful!")
        this.walletService.updateBalance();
      }




    }

    this.walletService.walletController.sendTransaction(transaction, handleApprovalTx);


  }
}
