/* /frontend/src/wallet_approval.js   */

const approvalRequest = {
    appName: "Hopium",
    version: "1.0.0",
    description: "Be the lucky one and win some TAU",
    contractName: "con_hopium",
    networkType: "mainnet",
    networkName: 'arko',
    logo: "assets/logo-192.png",
    background: "assets/background.png"
}

const baseURL = 'https://arko-mn-1.lamden.io'

export { approvalRequest, baseURL };
