import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GameComponent } from './game/game.component';
import { WalletButtonComponent } from './wallet-button/wallet-button.component';
import { HttpClientModule } from '@angular/common/http'
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, DEBUG_MODE } from '@angular/fire/analytics';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireAuthModule } from '@angular/fire/auth';
import { WinnersComponent } from './winners/winners.component';
import { HeaderLayoutComponent } from './header-layout/header-layout.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FaqComponent } from './faq/faq.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { TruncatePipe } from './truncate.pipe';
import { ToastNotificationsModule } from 'ngx-toast-notifications';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    GameComponent,
    WalletButtonComponent,
    WinnersComponent,
    HeaderLayoutComponent,
    NavigationComponent,
    FaqComponent,
    MaintenanceComponent,
    TruncatePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    ToastNotificationsModule
  ],
  providers: [ScreenTrackingService,
    {
      provide: DEBUG_MODE, useValue: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
