<!-- <div class="row">
    <div class="col col-sm-12 col-md-6">
        <p>installed: {{installed}}</p>
        <p>locked: {{locked}}</p>
        <p>setup: {{setup}}</p>
        <p>approvedAmount: {{approvedAmount}}</p>
        <p>accountBalance: {{accountBalance}}</p>
    </div>
</div> -->
<div class="row mt-2 justify-content-center">

    <div class="col col-sm-12 col-md-6 status-container" *ngIf="this.installed === undefined">
        <span class="status">Checking for installed Lamden Wallet...</span>
        <div>
            <svg width="32" height="32" style="fill: var(--secondary);">
                <use xlink:href=" #sandclock" />
            </svg>
        </div>
    </div>

    <div class="col col-sm-12 col-md-6 status-container"
        *ngIf="this.locked === false && this.installed === true && this.setup === true && this.approvedAmount === undefined">
        <span class="status">Checking for approval...</span>
        <div>
            <svg width="32" height="32" style="fill: var(--secondary);">
                <use xlink:href=" #sandclock" />
            </svg>
        </div>
    </div>

    <div class="col col-sm-12 col-md-6 status-container" *ngIf="this.installed === false">
        <span class=" status">To use Hopium you need to install the Lamden Wallet</span>
        <div>

            <a class="btn btn-primary"
                href="https://chrome.google.com/webstore/detail/lamden-wallet-browser-ext/fhfffofbcgbjjojdnpcfompojdjjhdim"
                target="_blank" role="button">
                <svg width="32" height="32" style="margin-right: 10px;">
                    <use xlink:href="#chrome-logo" />
                </svg>
                Install from chrome store</a>
        </div>
    </div>


    <div class="col col-sm-12 col-md-6 status-container" *ngIf="this.locked === true && this.installed === true">
        <span class=" status">Your wallet is locked, please unlock it</span>
        <div>
            <button type="button" class="btn btn-primary" (click)="unlockWallet()" [disabled]="unlockingDisabled">
                <svg width="32" height="32" style="fill: var(--secondary); margin-right: 10px;">
                    <use xlink:href="#unlock" />
                </svg>
                {{unlockingCaption}}
            </button>
        </div>
    </div>


    <div class="col col-sm-12 col-md-6 status-container"
        *ngIf="this.locked === false && this.installed === true && this.setup === true">
        <div class="row">
            <div class="col col-12 status">
                Everything OK, good luck & have fun!
            </div>
        </div>
        <div class="row">
            <div class="col col-12 offset-sm-2 col-sm-8">
                <table style="margin: 0 auto;">
                    <tbody>
                        <tr>
                            <td class="text-left">linked account:</td>
                            <td class="text-left" style="padding-left: 20px;"><a target=" _blank"
                                    href="https://mainnet.lamden.io/addresses/{{account}}">{{account | truncate:10}}</a>
                            </td>
                        </tr>
                        <tr>
                            <td class=" text-left">balance:</td>
                            <td class="text-left" style="padding-left: 20px;">{{this.accountBalance}} TAU</td>
                        </tr>
                        <tr>
                            <td class="text-left">approved:</td>
                            <td class="text-left" style="padding-left: 20px;">{{this.approvedAmount}} TAU</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!--     <svg width="32" height="32" style="fill: var(--secondary); margin-right: 10px;">
        <use xlink:href="#unlock" />
    </svg>
    Send approval -->

    <div class="col col-sm-12 col-md-6 status-container"
        *ngIf="this.installed === true && this.authorized === false && this.setup == undefined">
        <span class="status">Your wallet has no account linked to Hopium</span>
        <div>
            <button type="button" class="btn btn-primary" (click)="connectWallet()">Create Hopium account</button>
        </div>
    </div>
</div>


<svg width="437.46px" height="437.46px" viewBox="-0.82 0 437.46 437.46" xmlns="http://www.w3.org/2000/svg"
    style="height: 1px; width: 1px;">
    <symbol viewBox="-0.82 0 437.46 437.46" id="chrome-logo">
        <path
            d="M217.341.039s128.478-5.783 196.57 123.337H206.416s-39.188-1.289-72.593 46.255c-9.634 19.916-19.91 40.473-8.349 80.937C108.773 222.309 36.823 97.04 36.823 97.04S87.578 5.176 217.341.039z"
            fill="#c6352e" />
        <path
            d="M407.223 327.871s-59.247 114.143-205.118 108.533c17.995-31.148 103.772-179.682 103.772-179.682s20.709-33.289-3.744-85.991c-12.431-18.305-25.09-37.486-65.919-47.713 32.836-.326 177.285.021 177.285.021s54.168 89.891-6.276 204.832z"
            fill="#f4d911" />
        <path
            d="M28.373 328.738s-69.224-108.395 8.58-231.908c17.979 31.16 103.71 179.72 103.71 179.72s18.469 34.578 76.341 39.756c22.061-1.609 45.007-2.982 74.279-33.223-16.139 28.594-88.673 153.521-88.673 153.521S97.681 438.56 28.373 328.738z"
            fill="#81b354" />
        <path d="M202.105 437.46l29.187-121.793s32.092-2.504 58.982-32.017c-16.693 29.365-88.169 153.81-88.169 153.81z"
            fill="#7baa50" />
        <path
            d="M119.59 220.093c0-53.69 43.52-97.215 97.215-97.215 53.69 0 97.214 43.524 97.214 97.215 0 53.693-43.522 97.219-97.214 97.219-53.695 0-97.215-43.525-97.215-97.219z"
            fill="#fff" />
        <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="-829.128" y1="1417.339" x2="-829.128" y2="1261.441"
            gradientTransform="matrix(1 0 0 -1 1045.93 1557.636)">
            <stop offset="0" stop-color="#a2c0e6" />
            <stop offset="1" stop-color="#406cb1" />
        </linearGradient>
        <path
            d="M135.86 220.093c0-44.702 36.238-80.941 80.945-80.941 44.698 0 80.94 36.239 80.94 80.941 0 44.703-36.242 80.945-80.94 80.945-44.707.001-80.945-36.244-80.945-80.945z"
            fill="url(#a)" />
        <path d="M413.5 123.039l-120.183 35.237s-18.123-26.596-57.104-35.258c33.776-.115 177.287.021 177.287.021z"
            fill="#e7ce12" />
        <path d="M123.137 246.197c-16.89-29.25-86.31-149.16-86.31-149.16l89.029 88.07s-9.149 18.82-5.68 45.7l2.961 15.39z"
            fill="#bc332c" />
    </symbol>
</svg>



<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" display="none">
    <symbol viewBox="0 0 24 24" id="checkmark">
        <g data-name="Layer 2">
            <g data-name="checkmark-circle-2">
                <rect width="24" height="24" opacity="0" />
                <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" />
                <path
                    d="M14.7 8.39l-3.78 5-1.63-2.11a1 1 0 0 0-1.58 1.23l2.43 3.11a1 1 0 0 0 .79.38 1 1 0 0 0 .79-.39l4.57-6a1 1 0 1 0-1.6-1.22z" />
            </g>
        </g>
    </symbol>
</svg>

<!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30.393 30.393" style="enable-background:new 0 0 30.393 30.393;" xml:space="preserve" display="none">
    <symbol viewBox="0 0 30.393 30.393" id="sandclock">
        <g>
            <path d="M20.842,6.723V5.775H9.553v0.947c0,0,1.717,4.781,4.939,6.988v2.297c0,0-3.554,3.459-4.939,7.434v1.084h11.288v-1.084
		c-1.801-4.389-4.939-7.434-4.939-7.434V13.71C20.284,9.945,20.842,6.723,20.842,6.723z" />
            <path d="M24.91,4.963V3.256h1.619V0H3.864v3.256h1.621v1.707c0,2.607,4.677,9.725,4.896,10.238
		c-0.215,0.514-4.896,7.633-4.896,10.229v1.705H3.864v3.258h22.665v-3.258H24.91V25.43c0-2.607-4.679-9.68-4.929-10.234
		C20.231,14.643,24.91,7.568,24.91,4.963z M23.293,25.43v1.705H7.103V25.43c0-1.873,4.96-9.295,4.96-10.234s-4.96-8.359-4.96-10.232
        V3.256h16.189v1.707c0,1.873-5.002,9.293-5.002,10.232S23.293,23.557,23.293,25.43z" />
        </g>
    </symbol>
</svg>

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 491.52 491.52" style="enable-background:new 0 0 491.52 491.52;" xml:space="preserve" display="none">
    <symbol viewBox="0 0 491.52 491.52" id="unlock">
        <g>
            <g>
                <path d="M399.398,203.578h-19.802v-69.744C379.596,60.039,319.558,0,245.763,0h-0.004c-73.792,0.002-133.83,60.04-133.83,133.835
			v1.435h47.753v-1.435c0-23.734,9.653-45.254,25.24-60.841c3.896-3.896,8.165-7.423,12.744-10.519
			c9.162-6.193,19.572-10.671,30.77-12.968c5.596-1.149,11.392-1.753,17.323-1.753h0.004c47.467,0,86.08,38.613,86.08,86.081v69.744
			h-86.084h-86.077h-47.753H92.122c-15.658,0-28.35,12.692-28.35,28.35v17.246v196.749v17.246c0,15.658,12.692,28.35,28.35,28.35
			H245.76h153.639c15.656,0,28.35-12.692,28.35-28.35v-17.246V249.175v-17.246C427.747,216.27,415.055,203.578,399.398,203.578z
			 M274.161,403.177h-56.804l10.168-48.445c-9.507-6.05-15.858-16.614-15.858-28.719c0-18.828,15.263-34.092,34.092-34.092
			c18.83,0,34.092,15.263,34.092,34.092c0,12.104-6.35,22.669-15.856,28.718L274.161,403.177z" />

            </g>
        </g>
    </symbol>
</svg>



<!-- 
<div *ngIf="this.installed === undefined">
    Checking for installed Lamden Wallet...
</div>
<div *ngIf="this.installed === false">
    You should install the wallet here
</div>
<div *ngIf="this.locked === true && this.installed === true">
    Your wallet is locked, please unlock it
    
</div>
<div *ngIf="this.installed === true && this.authorized === false && this.setup == undefined">
    <button type="button" class="btn btn-primary" (click)="connectWallet()">Connect to wallet</button>
</div>

<div *ngIf="this.locked === false && this.installed === true && this.setup === true">
    Great, connection to wallet successful.
</div>

<div>
    <p>installed: {{installed}}</p>
    <p>locked: {{locked}}</p>
    <p>setup: {{setup}}</p>
    <p>authorized: {{authorized}}</p>

</div> -->